import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {};
const defaultProps = {};

const ProjectCategoriesTemplate = ({ title }) => <div>{title}</div>;

ProjectCategoriesTemplate.propTypes = propTypes;
ProjectCategoriesTemplate.defaultProps = defaultProps;

export default ProjectCategoriesTemplate;
